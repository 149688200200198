import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  mailTo: (str: ReactNode) => (
    <a href={`mailto:${str}`} style={{ color: '#D50000' }}>
      {str}
    </a>
  ),
};

export const calendarTranslations = (intl: IntlShape) => ({
  calendar: {
    navigation: {
      breadcrumbs: {
        matchId: intl.formatMessage({
          defaultMessage: 'Dettaglio Gara',
          id: 'calendar.navigation.breadcrumbs.matchId',
        }),
        creditManagement: intl.formatMessage({
          defaultMessage: 'Gestione Accrediti',
          id: 'calendar.navigation.breadcrumbs.creditManagement',
        }),
        newCredit: intl.formatMessage({
          defaultMessage: 'Nuovo Accredito',
          id: 'calendar.navigation.breadcrumbs.newCredit',
        }),
        creditDetail: intl.formatMessage({
          defaultMessage: 'Dettaglio Accredito',
          id: 'calendar.navigation.breadcrumbs.creditDetail',
        }),
        importRecap: intl.formatMessage({
          defaultMessage: 'Riepilogo import',
          id: 'calendar.navigation.breadcrumbs.importRecap',
        }),
      },
    },
    home: {
      tabs: {
        pastEvents: intl.formatMessage({
          defaultMessage: 'Eventi passati',
          id: 'calendar.home.tabs.pastEvents',
        }),
        currentEvents: intl.formatMessage({
          defaultMessage: 'Eventi correnti',
          id: 'calendar.home.tabs.currentEvents',
        }),
      },
      picker: {
        selectADate: intl.formatMessage({
          defaultMessage: 'Seleziona una data',
          id: 'calendar.home.picker.selectADate',
        }),
      },
      filters: {
        competitions: {
          name: intl.formatMessage({
            defaultMessage: 'Competizioni',
            id: 'calendar.home.filters.competitions',
          }),
          options: {
            all: intl.formatMessage({
              defaultMessage: 'Tutte',
              id: 'calendar.home.filters.options.all',
            }),
          },
        },
        order: {
          name: intl.formatMessage({
            defaultMessage: 'Ordinamento',
            id: 'calendar.home.filters.order.name',
          }),
          options: {
            asc: intl.formatMessage({
              defaultMessage: 'Crescente per data',
              id: 'calendar.home.filters.order.options',
            }),
            desc: intl.formatMessage({
              defaultMessage: 'Descrescente per data',
              id: 'calendar.home.filters.order.desc',
            }),
          },
        },
      },
      cards: {
        accreditations: {
          status: {
            Pending: intl.formatMessage({
              defaultMessage: 'Richiesta inviata',
              id: 'calendar.home.cards.accreditations.status.Pending',
            }),
            Rejected: intl.formatMessage({
              defaultMessage: 'Richiesta rifiutata',
              id: 'calendar.home.cards.accreditations.status.Rejected',
            }),
            Approved: intl.formatMessage({
              defaultMessage: 'Richiesta approvata',
              id: 'calendar.home.cards.accreditations.status.Approved',
            }),
            UnderManagement: intl.formatMessage({
              defaultMessage: 'Richiesta in gestione',
              id: 'calendar.home.cards.accreditations.status.UnderManagement',
            }),
          },
          request: intl.formatMessage({
            defaultMessage: 'Richiedi accredito',
            id: 'calendar.home.cards.accreditations.request',
          }),
          requestsClosed: intl.formatMessage({
            defaultMessage: 'Le richieste per la partita sono chiuse',
            id: 'calendar.home.cards.accreditations.requestsClosed',
          }),
          creditManagement: intl.formatMessage({
            defaultMessage: 'Gestisci accrediti',
            id: 'calendar.home.cards.accreditations.creditManagement',
          }),
          aggregate: {
            total: intl.formatMessage({
              defaultMessage: 'Richieste',
              id: 'calendar.home.cards.accreditations.aggregate.total',
            }),
            approved: intl.formatMessage({
              defaultMessage: 'Approvate / In gestione',
              id: 'calendar.home.cards.accreditations.aggregate.approved',
            }),
            rejected: intl.formatMessage({
              defaultMessage: 'Rifiutate',
              id: 'calendar.home.cards.accreditations.aggregate.rejected',
            }),
          },
          alert: {
            expiring: intl.formatMessage({
              defaultMessage: 'Le richieste di accredito verranno chiuse tra',
              id: 'calendar.home.cards.accreditations.alert.expiring',
            }),
            longTerm: intl.formatMessage({
              defaultMessage: 'Le richieste di accredito verranno chiuse il',
              id: 'calendar.home.cards.accreditations.alert.longTerm',
            }),
            hoursAnd: intl.formatMessage({
              defaultMessage: 'ore e',
              id: 'calendar.home.cards.accreditations.alert.hoursAnd',
            }),
            minutes: intl.formatMessage({
              defaultMessage: 'minuti',
              id: 'calendar.home.cards.accreditations.alert.minutes',
            }),
            seconds: intl.formatMessage({
              defaultMessage: 'secondi',
              id: 'calendar.home.cards.accreditations.alert.seconds',
            }),
          },
        },
      },
      dialog: {
        accreditationRequest: {
          fields: {
            category: intl.formatMessage({
              defaultMessage: 'Categoria',
              id: 'calendar.home.dialog.accreditationRequest.fields.category',
            }),
            organisation: intl.formatMessage({
              defaultMessage: 'Testata',
              id: 'calendar.home.dialog.accreditationRequest.fields.organisation',
            }),
          },
          organisationText: intl.formatMessage({
            defaultMessage:
              'Si prega di inserire la sola testata per la quale si richiede questo accredito',
            id: 'calendar.home.dialog.accreditationRequest.organisationText',
          }),
          requestButton: intl.formatMessage({
            defaultMessage: 'Richiedi Accredito',
            id: 'calendar.home.dialog.accreditationRequest.requestButton',
          }),
          outroText: intl.formatMessage({
            defaultMessage: 'La richiesta dovrà essere approvata da AC Milan',
            id: 'calendar.home.dialog.accreditationRequest.outroText',
          }),
        },
        accreditationDeletion: {
          title: intl.formatMessage({
            defaultMessage: 'Elimina richiesta di accredito',
            id: 'calendar.home.dialog.accreditationDeletion.title',
          }),
          content: intl.formatMessage({
            defaultMessage:
              'Confermi di voler annullare la tua richiesta di accredito? Verrà inoltrata una comunicazione ad AC Milan',
            id: 'calendar.home.dialog.accreditationDeletion.content',
          }),
          contentOf: intl.formatMessage({
            defaultMessage:
              'Confermi di voler annullare la richiesta di accredito di',
            id: 'calendar.home.dialog.accreditationDeletion.contentOf',
          }),
          contentOfCommunication: intl.formatMessage({
            defaultMessage: 'Verrà inoltrata una comunicazione ad AC Milan',
            id: 'calendar.home.dialog.accreditationDeletion.contentOfCommunication',
          }),
          confirmButton: intl.formatMessage({
            defaultMessage: 'Conferma',
            id: 'calendar.home.dialog.accreditationDeletion.confirmButton',
          }),
        },
      },
      snackbar: {
        accreditationCreated: intl.formatMessage({
          defaultMessage:
            "La tua richiesta è stata presa in carico. Ti avviseremo dell'esito tramite Email.",
          id: 'calendar.home.snackbar.accreditationCreated',
        }),
        accreditationDeleted: intl.formatMessage({
          defaultMessage: 'Richiesta di accredito eliminato con successo',
          id: 'calendar.home.snackbar.accreditationDeleted',
        }),
      },
    },
    match: {
      recap: {
        date: intl.formatMessage({
          defaultMessage: 'Data',
          id: 'calendar.match.recap.date',
        }),
        time: intl.formatMessage({
          defaultMessage: 'Ora',
          id: 'calendar.match.recap.time',
        }),
        competitionType: intl.formatMessage({
          defaultMessage: 'Tipologia',
          id: 'calendar.match.recap.competitionType',
        }),
        dayNumber: intl.formatMessage({
          defaultMessage: 'Giornata',
          id: 'calendar.match.recap.dayNumber',
        }),
        phase: intl.formatMessage({
          defaultMessage: 'Fase',
          id: 'calendar.match.recap.phase',
        }),
      },
      requestCards: {
        journalistsTitle: intl.formatMessage({
          defaultMessage: 'Giornalisti',
          id: 'calendar.match.requestCards.journalistsTitle',
        }),
        photographersTitle: intl.formatMessage({
          defaultMessage: 'Fotografi',
          id: 'calendar.match.requestCards.photographersTitle',
        }),
        requestsToHandle: intl.formatMessage({
          defaultMessage: 'Richieste da gestire',
          id: 'calendar.match.requestCards.requestsToHandle',
        }),
        totalRequests: intl.formatMessage({
          defaultMessage: 'Richieste ricevute',
          id: 'calendar.match.requestCards.totalRequests',
        }),
        confirmedRequests: intl.formatMessage({
          defaultMessage: 'Richieste confermate',
          id: 'calendar.match.requestCards.confirmedRequests',
        }),
        rejectedRequests: intl.formatMessage({
          defaultMessage: 'Richieste rifiutate',
          id: 'calendar.match.requestCards.rejectedRequests',
        }),
        handleButton: intl.formatMessage({
          defaultMessage: 'Gestisci',
          id: 'calendar.match.requestCards.handleButton',
        }),
      },
      buttons: {
        handleAccreditations: intl.formatMessage({
          defaultMessage: 'Gestisci Accrediti',
          id: 'calendar.match.buttons.handleAccreditations',
        }),
        showGrandstand: intl.formatMessage({
          defaultMessage: 'Visualizza tribuna',
          id: 'calendar.match.buttons.showGrandstand',
        }),
        printLists: {
          filename: intl.formatMessage({
            defaultMessage: 'Elenchi',
            id: 'calendar.match.buttons.printLists.filename',
          }),
          label: intl.formatMessage({
            defaultMessage: 'Stampa elenchi',
            id: 'calendar.match.buttons.printLists.label',
          }),
        },
        printSingleList: {
          filename: intl.formatMessage({
            defaultMessage: 'Singolo elenco',
            id: 'calendar.match.buttons.printSingleList.filename',
          }),
          filenamePrefix: intl.formatMessage({
            defaultMessage: 'singolo elenco_',
            id: 'calendar.match.buttons.printSingleList.filenamePrefix',
          }),
          label: intl.formatMessage({
            defaultMessage: 'Stampa singolo elenco',
            id: 'calendar.match.buttons.printSingleList.label',
          }),
        },
        printPlaceholders: {
          filename: intl.formatMessage({
            defaultMessage: 'Segnaposti',
            id: 'calendar.match.buttons.printPlaceholders.filename',
          }),
          label: intl.formatMessage({
            defaultMessage: 'Stampa segnaposti',
            id: 'calendar.match.buttons.printPlaceholders.label',
          }),
        },
        excelExport: intl.formatMessage({
          defaultMessage: 'Esporta informazioni in Excel',
          id: 'calendar.match.buttons.excelExport',
        }),
      },
      export: {
        placeHoldersPdf: {
          seat: intl.formatMessage({
            defaultMessage: 'Posto N.',
            id: 'calendar.match.export.placeHoldersPdf.seat',
          }),
        },
        singleListPdf: {
          modal: {
            title: intl.formatMessage({
              defaultMessage: 'Selezionare una categoria',
              id: 'calendar.match.export.singleListPdf.modal.title',
            }),
            category: intl.formatMessage({
              defaultMessage: 'Categoria',
              id: 'calendar.match.export.singleListPdf.modal.category',
            }),
            radios: {
              approved: intl.formatMessage({
                defaultMessage: 'Stampa approvati',
                id: 'calendar.match.export.singleListPdf.modal.radios.approved',
              }),
              pending: intl.formatMessage({
                defaultMessage: 'Stampa da approvare',
                id: 'calendar.match.export.singleListPdf.modal.radios.pending',
              }),
            },
          },
          title: intl.formatMessage({
            defaultMessage: 'Richieste di Accredito',
            id: 'calendar.match.export.singleListPdf.title',
          }),
          table: {
            name: intl.formatMessage({
              defaultMessage: 'Nominativo',
              id: 'calendar.match.export.singleListPdf.table.name',
            }),
            newspaper: intl.formatMessage({
              defaultMessage: 'Testata',
              id: 'calenedar.match.export.singleListPdf.table.newspaper',
            }),
            seat: intl.formatMessage({
              defaultMessage: 'N.Posto',
              id: 'calendar.match.export.singleListPdf.table.seat',
            }),
          },
        },
      },
      snackbars: {
        zeroCategoryResults: intl.formatMessage({
          defaultMessage:
            'Nessun elemento da esportare per la categoria selezionata',
          id: 'calendar.match.snackbars.zeroCategoryResults',
        }),
        zeroRersults: intl.formatMessage({
          defaultMessage: 'Nessun elemento da esportare',
          id: 'calendar.match.snackbars.zeroRersults',
        }),
      },
    },
    creditManagement: {
      title: intl.formatMessage({
        defaultMessage: 'Gestione Accrediti',
        id: 'calendar.creditManagement.title',
      }),
      buttons: {
        loadFromExcel: intl.formatMessage({
          defaultMessage: 'Carica Fotografi da Excel della Lega',
          id: 'calendar.match.buttons.loadFromExcel',
        }),
        addCredit: intl.formatMessage({
          defaultMessage: 'Aggiungi accredito',
          id: 'calendar.match.buttons.addCredit',
        }),
      },
      filters: {
        resultsCountSuffix: intl.formatMessage({
          defaultMessage: 'Elementi presenti',
          id: 'calendar.match.buttons.resultsCountSuffix',
        }),
        options: {
          common: {
            allF: intl.formatMessage({
              defaultMessage: 'Tutte',
              id: 'calendar.creditManagement.filters.options.common.allF',
            }),
            allM: intl.formatMessage({
              defaultMessage: 'Tutti',
              id: 'calendar.creditManagement.filters.options.common.allM',
            }),
          },
          status: {
            approved: intl.formatMessage({
              defaultMessage: 'Approvato',
              id: 'calendar.creditManagement.filters.options.status.approved',
            }),
            tobeapproved: intl.formatMessage({
              defaultMessage: 'Da approvare',
              id: 'calendar.creditManagement.filters.options.status.tobeapproved',
            }),
            revoked: intl.formatMessage({
              defaultMessage: 'Rifiutato',
              id: 'calendar.creditManagement.filters.options.status.revoked',
            }),
          },
        },
        fields: {
          name: intl.formatMessage({
            defaultMessage: 'Nome Cognome',
            id: 'calendar.creditManagement.filters.fields.name',
          }),
          newspaper: intl.formatMessage({
            defaultMessage: 'Testata giornalistica',
            id: 'calendar.creditManagement.filters.fields.newspaper',
          }),
          status: intl.formatMessage({
            defaultMessage: 'Stato',
            id: 'calendar.creditManagement.filters.fields.status',
          }),
          category: intl.formatMessage({
            defaultMessage: 'Categoria',
            id: 'calendar.creditManagement.filters.fields.category',
          }),
        },
      },
      credits: {
        name: intl.formatMessage({
          defaultMessage: 'Nominativo',
          id: 'calendar.creditManagement.credits.name',
        }),
        newspaper: intl.formatMessage({
          defaultMessage: 'Testata',
          id: 'calendar.creditManagement.credits.newspaper',
        }),
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          id: 'calendar.creditManagement.credits.category',
        }),
        seat: intl.formatMessage({
          defaultMessage: 'Posto',
          id: 'calendar.creditManagement.credits.seat',
        }),
        sideline: intl.formatMessage({
          defaultMessage: 'Interno Campo',
          id: 'calendar.creditManagement.credits.sideline',
        }),
        assignedWithoutSeat: intl.formatMessage({
          defaultMessage: 'Non Assegnato',
          id: 'calendar.creditManagement.credits.assignedWithoutSeat',
        }),
        status: {
          Approved: intl.formatMessage({
            defaultMessage: 'Approvato',
            id: 'calendar.creditManagement.credits.status.approved',
          }),
          UnderManagement: intl.formatMessage({
            defaultMessage: 'Approvato',
            id: 'calendar.creditManagement.credits.status.underMananagement',
          }),
          Pending: intl.formatMessage({
            defaultMessage: 'Da approvare',
            id: 'calendar.creditManagement.credits.status.pending',
          }),
          Rejected: intl.formatMessage({
            defaultMessage: 'Rifiutato',
            id: 'calendar.creditManagement.credits.status.rejected',
          }),
          All: intl.formatMessage({
            defaultMessage: 'Tutti',
            id: 'calendar.creditManagement.credits.status.all',
          }),
        },
      },
      dialog: {
        import: {
          title: intl.formatMessage({
            defaultMessage: 'Caricamento da file Excel della Lega',
            id: 'calendar.creditManagement.dialog.import.title',
          }),
          subtitle: intl.formatMessage({
            defaultMessage: 'Seleziona il file con i record da importare',
            id: 'credit.creditManagement.dialog.import.subtitle',
          }),
          illustrationTitle: intl.formatMessage({
            defaultMessage: 'Seleziona file',
            id: 'calendar.creditManagement.dialog.import.illustrationTitle',
          }),
          buttons: {
            proceed: intl.formatMessage({
              defaultMessage: 'Procedi',
              id: 'calendar.creditManagement.dialog.import.proceed',
            }),
          },
        },
        userSelection: {
          title: intl.formatMessage({
            defaultMessage: 'Seleziona un utente per questo accredito',
            id: 'calendar.creditManagement.dialog.userSelection.title',
          }),
          subtitle: intl.formatMessage({
            defaultMessage:
              'Seleziona un giornalista / fotografo al quale assegnare il nuovo accredito',
            id: 'calendar.creditManagement.dialog.userSelection.subtitle',
          }),
          searchBar: intl.formatMessage({
            defaultMessage: 'Digita il nome di un giornalista o fotografo',
            id: 'calendar.creditManagement.dialog.userSelection.searchBar',
          }),
          buttons: {
            proceed: intl.formatMessage({
              defaultMessage: 'Procedi',
              id: 'calendar.creditManagement.dialog.userSelection.proceed',
            }),
          },
        },
      },
      snackbar: {
        createAccSuccess: intl.formatMessage({
          defaultMessage: 'Accredito creato con successo',
          id: 'calendar.creditManagement.snackbar.createAccSuccess',
        }),
      },
    },
    import: {
      snackbars: {
        success: intl.formatMessage({
          defaultMessage: 'Import eseguito con successo',
          id: 'calendar.creditManagement.import.snackbars.success',
        }),
        fileFormat: intl.formatMessage({
          defaultMessage:
            "Impossibile eseguire l'import, ricontrolla il formato del file",
          id: 'calendar.creditManagement.import.snackbars.fileFormat',
        }),
      },
    },
    importRecap: {
      title: intl.formatMessage({
        defaultMessage: 'Riepilogo import',
        id: 'calendar.creditManagement.importRecap.title',
      }),
      illustrationTitle: intl.formatMessage({
        defaultMessage: 'Import terminato',
        id: 'calendar.creditManagement.importRecap.illustrationTitle',
      }),
      illustrationSubitle: intl.formatMessage({
        defaultMessage: 'Vedi sotto il riepilogo',
        id: 'calendar.creditManagement.importRecap.illustrationSubitle',
      }),
      recapCards: {
        total: intl.formatMessage({
          defaultMessage: 'Record totali',
          id: 'calendar.creditManagement.importRecap.recapCards.total',
        }),
        success: intl.formatMessage({
          defaultMessage: 'Record importati con successo',
          id: 'calendar.creditManagement.importRecap.recapCards.success',
        }),
        failed: intl.formatMessage({
          defaultMessage: 'Record non importati',
          id: 'calendar.creditManagement.importRecap.recapCards.failed',
        }),
      },
      notImportedtitle: intl.formatMessage({
        defaultMessage: 'Record non importati',
        id: 'calendar.creditManagement.importRecap.notImportedtitle',
      }),
    },
    creditRequest: {
      title: intl.formatMessage({
        defaultMessage: 'Gestione Accrediti',
        id: 'calendar.creditRequest.title',
      }),
      currentSeasonRequests: intl.formatMessage({
        defaultMessage: 'Richieste nella stagione corrente',
        id: 'calendar.creditRequest.currentSeasonRequests',
      }),
      fields: {
        name: intl.formatMessage({
          defaultMessage: 'Nome',
          id: 'calendar.creditRequest.fields.name',
        }),
        surname: intl.formatMessage({
          defaultMessage: 'Cognome',
          id: 'calendar.creditRequest.fields.surname',
        }),
        organisation: intl.formatMessage({
          defaultMessage: 'Testata',
          id: 'calendar.creditRequest.fields.organisation',
        }),
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          id: 'calendar.creditRequest.fields.category',
        }),
        birthdate: intl.formatMessage({
          defaultMessage: 'Data di nascita',
          id: 'calendar.creditRequest.fields.birthdate',
        }),
        birthplace: intl.formatMessage({
          defaultMessage: 'Luogo di nascita',
          id: 'calendar.creditRequest.fields.birthplace',
        }),
        gender: intl.formatMessage({
          defaultMessage: 'Sesso',
          id: 'calendar.creditRequest.fields.gender',
        }),
        email: intl.formatMessage({
          defaultMessage: 'Email',
          id: 'calendar.creditRequest.fields.email',
        }),
        creditRequests: intl.formatMessage({
          defaultMessage: 'Richieste accrediti',
          id: 'calendar.creditRequest.fields.creditRequests',
        }),
        confirmedCreditRequests: intl.formatMessage({
          defaultMessage: 'Richieste accrediti confermati',
          id: 'calendar.creditRequest.fields.confirmedCreditRequests',
        }),
      },
      buttons: {
        assignSeat: intl.formatMessage({
          defaultMessage: 'Assegna posto',
          id: 'calendar.creditRequest.buttons.assignSeat',
        }),
        confirm: intl.formatMessage({
          defaultMessage: 'Conferma',
          id: 'calendar.creditRequest.buttons.confirm',
        }),
        rejectRequest: intl.formatMessage({
          defaultMessage: 'Rifiuta richiesta',
          id: 'calendar.creditRequest.buttons.rejectRequest',
        }),
        changeSeat: intl.formatMessage({
          defaultMessage: 'Cambia posto',
          id: 'calendar.creditRequest.buttons.changeSeat',
        }),
        edit: intl.formatMessage({
          defaultMessage: 'Modifica',
          id: 'calendar.creditRequest.buttons.edit',
        }),
        saveEditing: intl.formatMessage({
          defaultMessage: 'Salva Modifiche',
          id: 'calendar.creditRequest.buttons.saveEditing',
        }),
        assignWithouSeat: intl.formatMessage({
          defaultMessage: 'Assegna senza posto',
          id: 'calendar.creditRequest.buttons.assignWithoutSeat',
        }),
        sendAccreditationEmail: intl.formatMessage({
          defaultMessage: 'Invia conferma di accredito',
          id: 'calendar.creditRequest.buttons.sendAccreditationEmail',
        }),
      },
      dialog: {
        reject: {
          title: intl.formatMessage({
            defaultMessage:
              'Sei sicuro di voler rifiutare la richiesta di accredito?',
            id: 'calendar.creditRequest.dialog.reject.title',
          }),
          content: intl.formatMessage({
            defaultMessage:
              "L'utente riceverà una comunicazione al suo indirizzo email",
            id: 'calendar.creditRequest.dialog.reject.content',
          }),
          reject: intl.formatMessage({
            defaultMessage: 'Rifiuta',
            id: 'calendar.creditRequest.dialog.reject.reject',
          }),
        },
        confirmPhotographer: {
          title: intl.formatMessage({
            defaultMessage: 'Conferma Posto',
            id: 'calendar.creditRequest.dialog.confirmPhotographer.title',
          }),
          message: intl.formatMessage({
            defaultMessage:
              "Sei sicuro di confermare l'accredito per questo fotografo? Gli verrà inviata una notifica via email.",
            id: 'calendar.creditRequest.dialog.confirmPhotographer.message',
          }),
          confirmButton: intl.formatMessage({
            defaultMessage: 'Conferma',
            id: 'calendar.creditRequest.dialog.confirmPhotographer.confirmButton',
          }),
        },
        sendAccreditationEmail: {
          title: intl.formatMessage({
            defaultMessage: 'Invia conferma di accredito',
            id: 'calendar.creditRequest.dialog.sendAccreditationEmail.title',
          }),
          message: intl.formatMessage({
            defaultMessage:
              'Confermi di volere inviare la conferma di accredito?',
            id: 'calendar.creditRequest.dialog.sendAccreditationEmail.message',
          }),
          confirmButton: intl.formatMessage({
            defaultMessage: 'Conferma',
            id: 'calendar.creditRequest.dialog.sendAccreditationEmail.confirmButton',
          }),
        },
      },
      snackbar: {
        rejectAccSuccess: intl.formatMessage({
          defaultMessage: 'Accredito rifiutato con successo',
          id: 'calendar.creditRequest.snackbar.rejectAccSuccess',
        }),
        confirmAccSuccess: intl.formatMessage({
          defaultMessage: 'Accredito approvato con successo',
          id: 'calendar.creditRequest.snackbar.confirmAccSuccess',
        }),
        assignAccSuccess: intl.formatMessage({
          defaultMessage: 'Posto assegnato con successo',
          id: 'calendar.creditRequest.snackbar.assignAccSuccess',
        }),
        creditUpdateSuccess: intl.formatMessage({
          defaultMessage: 'Accredito aggiornato con successo',
          id: 'calendar.creditRequest.snackbar.creditUpdateSuccess',
        }),
        sendAccreditationEmailSuccess: intl.formatMessage({
          defaultMessage: 'Conferma di accredito inviata con successo',
          id: 'calendar.creditRequest.snackbar.sendAccreditationEmailSuccess',
        }),
      },
    },
    map: {
      title: intl.formatMessage({
        defaultMessage: 'Mappa',
        id: 'calendar.map.title',
      }),
      seat: {
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          id: 'calendar.map.seat.category',
        }),
        organisation: intl.formatMessage({
          defaultMessage: 'Testata',
          id: 'calendar.map.seat.organisation',
        }),
        seat: intl.formatMessage({
          defaultMessage: 'Posto',
          id: 'calendar.map.seat.seat',
        }),
        observerPrefix: intl.formatMessage({
          defaultMessage: 'OB',
          id: 'calendar.map.seat.observerPrefix',
        }),
      },
      legend: {
        monitor: intl.formatMessage({
          defaultMessage: 'Monitor',
          description: 'Calendar credit map legend free',
          id: 'calendar.map.legend.monitor',
        }),
        free: intl.formatMessage({
          defaultMessage: 'Postazione libera',
          id: 'calendar.map.legend.free',
        }),
        assigned: intl.formatMessage({
          defaultMessage: 'Accredito per la partita',
          id: 'calendar.map.legend.assigned',
        }),
        observer: intl.formatMessage({
          defaultMessage: 'Observer',
          id: 'calendar.map.legend.observer',
        }),
      },
      accreditationsWithoutSeat: intl.formatMessage({
        defaultMessage: 'Accrediti senza posto assegnato',
        id: 'calendar.map.accreditationsWithoutSeat',
      }),
      dialog: {
        assign: {
          title: intl.formatMessage({
            defaultMessage: 'Assegna Posto',
            id: 'calendar.map.dialog.assign.title',
          }),
          message: intl.formatMessage({
            defaultMessage:
              'Sei sicuro di assegnare il posto al giornalista? Gli verrà inviata una notifica via email.',
            id: 'calendar.map.dialog.assign.message',
          }),
          assignButton: intl.formatMessage({
            defaultMessage: 'Assegna',
            id: 'calendar.map.dialog.assign.assignButton',
          }),
        },
        sendAllMails: {
          title: intl.formatMessage({
            defaultMessage: 'Invia email accrediti',
            id: 'calendar.map.dialog.sendAllMails.title',
          }),
          message: intl.formatMessage({
            defaultMessage:
              'Confermi di volere inviare a tutti gli utenti la conferma di accredito?',
            id: 'calendar.map.dialog.sendAllMails.message',
          }),
          confirmButton: intl.formatMessage({
            defaultMessage: 'Conferma',
            id: 'calendar.map.dialog.sendAllMails.confirmButton',
          }),
        },
      },
      buttons: {
        sendAllMails: intl.formatMessage({
          defaultMessage: 'Invia tutti',
          id: 'calendar.map.buttons.sendAllMails',
        }),
        changeSeat: intl.formatMessage({
          defaultMessage: 'Cambia posto',
          description: 'Calendar credit map change seat button',
          id: 'calendar.map.buttons.changeSeat',
        }),
        handleMonitor: intl.formatMessage({
          defaultMessage: 'Gestisci monitor',
          description: 'Calendar credit map monitor button',
          id: 'calendar.map.buttons.handleMonitor',
        }),
        export: intl.formatMessage({
          defaultMessage: 'Esporta mappa',
          id: 'calendar.map.buttons.export',
        }),
      },
      snackbar: {
        sendAllEmailsSuccsss: intl.formatMessage({
          defaultMessage: 'Conferme di accredito inviate con successo',
          id: 'calendar.map.snackbar.sendAllEmailsSuccsss',
        }),
      },
    },
    recapSecretariat: {
      title: intl.formatMessage({
        defaultMessage: 'Gestisci Accrediti',
        id: 'calendar.recapSecretariat.title',
      }),
      breadcrumbs: intl.formatMessage({
        defaultMessage: 'Gestione Accrediti',
        id: 'calendar.recapSecretariat.breadcrumbs',
      }),
      cards: {
        sent: intl.formatMessage({
          defaultMessage: 'Richieste di accredito inviate',
          id: 'calendar.recapSecretariat.cards.sent',
        }),
        approved: intl.formatMessage({
          defaultMessage: 'Richieste di accredito approvate / in gestione',
          id: 'calendar.recapSecretariat.cards.approved',
        }),
        rejected: intl.formatMessage({
          defaultMessage: 'Richieste di accredito rifiutate',
          id: 'calendar.recapSecretariat.cards.rejected',
        }),
      },
      listTitle: intl.formatMessage({
        defaultMessage: 'Richieste di accredito',
        id: 'calendar.recapSecretariat.listTitle',
      }),
      listSubTitle: intl.formatMessage({
        defaultMessage: 'Le richieste dovranno essere approvate da AC Milan',
        id: 'calendar.recapSecretariat.listSubTitle',
      }),
      noUser: intl.formatMessage(
        {
          defaultMessage:
            'Non hai ancora dei giornalisti associati perciò al momento non puoi richiedere accrediti: aspetta qualche giorno che AC Milan aggiunga i tuoi giornalisti, se il problema persiste scrivi a <mailTo>press@acmilan.com</mailTo> la lista dei giornalisti che vorresti abilitare (indicando per ognuno Nome, Cognome, Email, Data e Luogo di Nascita e numero di tessera del giornalista)',
          id: 'calendar.recapSecretariat.noUser',
        },
        { ...htmlParserOptions }
      ),
      buttons: {
        cta: intl.formatMessage({
          defaultMessage: 'Richiedi accrediti',
          id: 'calendar.recapSecretariat.buttons.cta',
        }),
        approved: intl.formatMessage({
          defaultMessage: 'Richiesta approvata',
          id: 'calendar.recapSecretariat.buttons.approved',
        }),
        sent: intl.formatMessage({
          defaultMessage: 'Richiesta inviata',
          id: 'calendar.recapSecretariat.buttons.sent',
        }),
        underManagement: intl.formatMessage({
          defaultMessage: 'Richiesta in gestione',
          id: 'calendar.recapSecretariat.buttons.underManagement',
        }),
        rejected: intl.formatMessage({
          defaultMessage: 'Richiesta rifiutata',
          id: 'calendar.recapSecretariat.buttons.rejected',
        }),
      },
    },
  },
});
