import { MatchEnumsCompetition, MatchEnumsStatus } from './match';
import { ISeason } from './seasons';
import { IUser, UserEnumsCategory } from './user';

export interface IAccreditation {
  id: number;
  organisation: string;
  category: UserEnumsCategory;
  status: IAccreditationStatus;
  seat?: string;
  seatType: SeatType;
  hasMonitor: boolean;
  user: IUser;
  totalRequestsCount: number;
  approvedRequestsCount: number;
  emailSentDate: string;
}

// strings on dates always intended as ISO datestrings
export interface ICalendarMatch {
  id: number;
  opponent: string;
  stage: string;
  date: string;
  time: string;
  dateTime: string;
  accreditationDateLimit: string | null;
  competition: MatchEnumsCompetition;
  status: MatchEnumsStatus;
  accreditationsCount: number;
  season: ISeason;
  formattedDay?: string;
  formattedTime?: string;
  secreteriatAccreditationsAggregate?: ISecretariatAccAggregate;
  userAccreditation?: Omit<
    IAccreditation,
    'totalRequestsCount' | 'approvedRequestsCount'
  >;
}

export interface IJournalistRecap {
  totalCount: number;
  pendingCount: number;
  approvedCount: number;
  rejectedCount: number;
}

export enum SeatType {
  SEAT = 'Seat',
  OBSERVER = 'Observer',
  SIDELINE = 'Sideline',
  NON_ASSEGNATO = 'Non Assegnato',
}

export enum IAccreditationStatus {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  UNDER_MANAGEMENT = 'UnderManagement',
  ALL = 'All',
}

export type IAccreditationEnumsStatus = `${IAccreditationStatus}`;
export type ISeatTypeEnums = `${SeatType}`;

export interface IPhotoImportItem {
  name: string;
  surname: string;
  council?: string;
  nation?: string;
  email: string;
  gender: string;
  birthDate: string;
  category: UserEnumsCategory;
  organisation: string;
}
export interface IPhotoImportItemExtended extends IPhotoImportItem {
  matchId: number;
}

export interface ISecretariatAccAggregate {
  approved: number;
  rejected: number;
  total: number;
}

export interface IUserSecretariatAccreditation extends IUser {
  accreditation: IAccreditation;
}
