import useFetch from 'use-http';
import * as API from 'constants/api';
import { ACCREDITATIONS, MATCHES } from 'constants/api';
import { useGlobalSelector } from 'stores';
import {
  IAccreditation,
  IAccreditationStatus,
  ICalendarMatch,
  IPhotoImportItemExtended,
  IUserSecretariatAccreditation,
} from 'types/accreditations';
import { ICustomApiError } from 'types/error';
import {
  IAccreditationExportHelperProps,
  IAccreditationsFilters,
  ICalendarMatchFilters,
  IDeletionAccreditationHelperProps,
  IHttpHelperExtendedProps,
  IHttpMutateHelperProps,
  IHttpNoResponseHelperProps,
} from 'types/httpClient';
import {
  ICreateAccreditationInput,
  IEditAccreditationInput,
  IImportInput,
  IUpdateAccreditationInput,
} from 'types/request';
import {
  IAccreditationsResponse,
  IExportResponse,
  IMatchRecapResponse,
} from 'types/response';
import { logApiError } from 'utils/api';
import { createFiltersQuery } from 'utils/data';

const useAccreditations = () => {
  const { get, post, put, del } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();

  const getAccreditationsExportData = async ({
    useLoader,
    setData,
    accreditationStatus = IAccreditationStatus.APPROVED,
    id,
  }: IAccreditationExportHelperProps<IExportResponse | null>): Promise<IExportResponse | null> => {
    try {
      useLoader && showLoader();
      const data = await get(
        `${MATCHES}/${id}/export?accreditationStatus=${accreditationStatus}`
      );
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'get-accreditations-export-data');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const listCurrentSeasonMatches = async ({
    useLoader,
    setData,
    body = {},
  }: IHttpMutateHelperProps<
    ICalendarMatch[] | undefined,
    ICalendarMatchFilters
  >): Promise<ICalendarMatch[] | ICustomApiError | boolean> => {
    try {
      useLoader && showLoader();
      const params = body ? createFiltersQuery(body) : '';
      const data = await get(`${MATCHES}/calendar` + params);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'list-current-season-matches');
      if ('key' in err) return err;
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const getMatchRecap = async ({
    useLoader,
    setData,
    id,
  }: IHttpHelperExtendedProps<IMatchRecapResponse | null>): Promise<
    IMatchRecapResponse | boolean
  > => {
    try {
      useLoader && showLoader();
      const data = await get(`${MATCHES}/${id}/recap`);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'get-match-recap');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const listMatchAccreditations = async ({
    useLoader,
    setData,
    body = {},
  }: IHttpMutateHelperProps<
    IAccreditationsResponse | undefined,
    IAccreditationsFilters
  >): Promise<IAccreditationsResponse | false> => {
    try {
      useLoader && showLoader();
      const params = body ? createFiltersQuery(body) : '';
      const data = await get(`${ACCREDITATIONS}` + params);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'list-match-accreditations');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const listUserSecretariatAccreditations = async ({
    useLoader,
    setData,
    id,
  }: IHttpHelperExtendedProps<IUserSecretariatAccreditation[] | null>): Promise<
    IUserSecretariatAccreditation[] | null
  > => {
    try {
      useLoader && showLoader();
      const data = await get(`${ACCREDITATIONS}/secretariat/${id}`);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'list-user-secretariat-accreditations');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const getAccreditation = async ({
    useLoader,
    setData,
    id,
  }: IHttpHelperExtendedProps<IAccreditation | null>): Promise<IAccreditation | null> => {
    try {
      useLoader && showLoader();
      const data = await get(`${ACCREDITATIONS}/${id}`);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'get-accreditation');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const createMassiveAccreditations = async ({
    useLoader,
    body,
  }: IHttpNoResponseHelperProps<{
    accreditations: ICreateAccreditationInput[];
  }>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await post(`${ACCREDITATIONS}/bulk`, body);
      return true;
    } catch (err) {
      logApiError(err, 'create-massive-accreditations');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const createAccreditation = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<
    IAccreditation | null,
    ICreateAccreditationInput
  >): Promise<IAccreditation | null> => {
    try {
      useLoader && showLoader();
      const data = await post(ACCREDITATIONS, body);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'create-accreditation');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const editAccreditation = async ({
    useLoader,
    setData,
    id,
    body = {},
  }: IHttpMutateHelperProps<
    IAccreditation | null,
    IEditAccreditationInput
  >): Promise<IAccreditation | null> => {
    try {
      useLoader && showLoader();
      const data = await put(
        `${ACCREDITATIONS}/${id}/${body.seatType ? 'assignSeat' : 'reject'}`,
        body
      );
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'edit-accreditation');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const toggleMonitor = async ({
    useLoader,
    id,
  }: IHttpHelperExtendedProps<IAccreditation | void>): Promise<IAccreditation | void> => {
    try {
      useLoader && showLoader();
      const data: IAccreditation = await put(`${ACCREDITATIONS}/${id}/monitor`);
      return data;
    } catch (err) {
      logApiError(err, 'toggle-monitor');
    } finally {
      useLoader && hideLoader();
    }
  };

  const updateAccreditationData = async ({
    useLoader,
    setData,
    id,
    body = {},
  }: IHttpMutateHelperProps<
    IAccreditation | null,
    IUpdateAccreditationInput
  >): Promise<IAccreditation | null> => {
    try {
      useLoader && showLoader();
      const data = await put(`${ACCREDITATIONS}/${id}`, body);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'update-accreditation');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const getInDeletionAccreditations = async ({
    useLoader,
    setData,
    matchId,
    seasonId,
  }: IDeletionAccreditationHelperProps<IAccreditation[] | void>) => {
    try {
      if (!matchId && !seasonId)
        throw new Error('Accreditations request needs matchId or seasonId');
      useLoader && showLoader();

      const qs = matchId ? `?matchId=${matchId}` : `?seasonId=${seasonId}`;

      const data: IAccreditation[] = await get(
        `${API.ACCREDITATIONS_EXPORT}${qs}`
      );
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'get-in-deletion-accreditations');
    } finally {
      useLoader && hideLoader();
    }
  };

  const importPhotographers = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<IPhotoImportItemExtended[], IImportInput>): Promise<
    IPhotoImportItemExtended[] | void
  > => {
    try {
      useLoader && showLoader();
      const res: IPhotoImportItemExtended[] = await post(
        API.ACCREDITATIONS_IMPORT,
        body
      );
      setData?.(res);
      return res;
    } catch (err) {
      logApiError(err);
    } finally {
      useLoader && hideLoader();
    }
  };

  const deleteAccreditation = async ({
    useLoader,
    body,
  }: IHttpNoResponseHelperProps<{
    id: number;
  }>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await del(`${API.ACCREDITATIONS}/${body?.id}`);
      return true;
    } catch (err) {
      logApiError(err, 'delete-accreditation');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const sendSingleAccreditationEmail = async ({
    useLoader,
    id,
  }: IHttpHelperExtendedProps<void>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await post(`${ACCREDITATIONS}/${id}/email`);
      return true;
    } catch (err) {
      logApiError(err, 'sendSingleAccreditationEmail');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  const sendAllAccreditationsEmails = async ({
    useLoader,
    id,
  }: IHttpHelperExtendedProps<void>): Promise<boolean> => {
    try {
      useLoader && showLoader();
      await post(`${ACCREDITATIONS}/${id}/emails`);
      return true;
    } catch (err) {
      logApiError(err, 'sendAllAccreditationsEmails');
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  return {
    listCurrentSeasonMatches,
    getMatchRecap,
    listMatchAccreditations,
    getAccreditationsExportData,
    getAccreditation,
    createAccreditation,
    editAccreditation,
    toggleMonitor,
    updateAccreditationData,
    getInDeletionAccreditations,
    importPhotographers,
    deleteAccreditation,
    listUserSecretariatAccreditations,
    createMassiveAccreditations,
    sendSingleAccreditationEmail,
    sendAllAccreditationsEmails,
  };
};

export { useAccreditations };
